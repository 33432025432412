import { Helmet } from 'react-helmet';
import React from 'react';

const OGMetaTagUrl = () => (
  <Helmet>
    <meta
      data-property="og:url"
      property="og:url"
      content={window.location.href}
    />
  </Helmet>
);

export default OGMetaTagUrl;
