const parse = require('date-fns/parse');

const format = (date) => {
  const day = new Date(date);
  const dayWrapper = parse(day);
  const formated = String(dayWrapper);
  const split = formated.split(' ');
  const final = `${split[1]} ${split[2]} ${split[3]}`;
  return final;
};
export default format;
