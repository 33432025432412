import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Albums from './containers/Album/index';
import Landing from './containers/Landing/index';
import NotFound from './containers/NotFound';
import Privacy from './containers/Privacy-Policy/index';
import Term from './containers/TermOfService/index';

const mapStateToProps = state => ({
  code: state.albums.code,
});

const Home = () => (
  <BrowserRouter>
    <div className="App">
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/index.html" component={Landing} />
        <Route path="/:username/album/:code" component={Albums} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/terms-of-service" component={Term} />
        <Route path="/404" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </div>
  </BrowserRouter>
);

export default connect(mapStateToProps, null)(Home);
