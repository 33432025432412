import React from 'react';

const FooterAbout = (props) => (
  <div className="text-center mb-4">
    <ul className="list-unstyled">
      <li className="list-inline-item px-1"><a href="/privacy-policy">Privacy Policy</a></li>
      <li className="list-inline-item px-1"><a href="/terms-of-service">Terms of Service</a></li>
      <li className="list-inline-item px-1"><a href="mailto:support@travly.me">Contact Us</a></li>
    </ul>
  </div>
);

export default FooterAbout;
