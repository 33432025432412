import React from 'react';

const Download = () => (
  <section className="section-lg bg-feature d-flex">
    <div className="container">
      <div className="row align-items-center justify-content-center h-100">
        <div className="col-lg-10 text-center">
          <h2 className="h1 text-white">Download and start your Travly!</h2>
          <p className="lead text-white">Organise and share your trip with friends today</p>
          <a className="btn btn-primary btn-translate-hover my-3 btn-lg" href="https://itunes.apple.com/us/app/travly-travel-photo-journal/id1112438513">Download now <i className="fas fa-chevron-right ml-3"></i></a>
        </div>
      </div>
    </div>
    <div className="overlay"></div>
  </section>
);

export default Download;
