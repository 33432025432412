import React from 'react';
import iconFreeStorage from '../../assets/img/icon-free-storage.svg';
import iconLocation from '../../assets/img/icon-file-location.svg';
import iconShare from '../../assets/img/icon-share-albums.svg';

const BGText = () => (

  <section className="section-lg bg-white" id="benefits">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <h2 className="h2 text-center mb-4">Travly makes it easy to create a beautiful travel journal</h2>
          <p className="lead text-center mb-4">Designed by travelers for travelers, Travly intuitively manages your photo and video files as you go.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0 text-center">
          <div className="icon-holder my-4">
            <img src={iconFreeStorage} alt="" />
          </div>
          <h3 className="h4 text-center">Free 2.5GB storage</h3>
          <p className="text-center px-lg-3">We store all your photos and digital assets in a highly secure storage platform with 256 bit encryption.</p>
        </div>
        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0 text-center">
          <div className="icon-holder my-4">
            <img src={iconLocation} alt="" />
          </div>
          <h3 className="h4 text-center">Collaborate with friends</h3>
          <p className="text-center px-lg-3">Travly makes it easy for friends to collaborate with your photos and journal entries. Share the travel experience with friends.</p>
        </div>
        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0 text-center">
          <div className="icon-holder my-4">
            <img src={iconShare} alt="" />
          </div>
          <h3 className="h4 text-center">Create your own website</h3>
          <p className="text-center px-lg-3">With the push of a button you can publish your album to your unique website. Keep forever, share with anyone, anywhere.</p>
        </div>
      </div>
    </div>
  </section>
);
export default BGText;
