import React from 'react';
import appScreen from '../../../assets/img/app-screens.png';

const AboutImage = () => (
  <div className="position-relative mb-5 mb-md-0">
    <img className="img-fluid" src={appScreen} alt="" />
  </div>
);

export default AboutImage;
