import React from 'react';
import ReactPixel from 'react-facebook-pixel';

import logo from '../../assets/img/travly-logo.svg';
import '../../assets/css/styles.css';
import Index from '../../components/Footer';

class Privacy extends React.Component {
  componentDidMount() {
    window.prerenderReady = true;
    ReactPixel.pageView();
  }

  render() {
    return (
      <>
        <header className="header bg-gradient">
          <nav className="navbar d-flex justify-content-center align-items-center">
            <div className="container">
              <a href="/"><img className="logo" src={logo} alt="" /></a>
              <nav className="nav">
                <ul className="nav__links">
                  <li><a href="/#benefits">How it works</a></li>
                  <li><a href="/#features">Features</a></li>
                  <li><a href="/#reviews">Reviews</a></li>
                  <li><a href="/blog/">Blog</a></li>
                </ul>
              </nav>
              <a href="mailto:support@travly.me" className="btn btn-outline-light btn-md nav__btn">Contact us</a>
              <div className="mobile__menu">
                <input id="toggle" type="checkbox" />
                <label className="hamburger" htmlFor="toggle">
                  <div className="hamburger__top"></div>
                  <div className="hamburger__middle"></div>
                  <div className="hamburger__bottom"></div>
                </label>
                <div className="hamburger__nav">
                  <div className="hamburger__nav--wrapper">
                    <nav className="hamburger__nav--items">
                      <a href="/#benefits">How it works</a>
                      <a href="/#features">Features</a>
                      <a href="/#reviews">Reviews</a>
                      <a href="/blog/">Blog</a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <article className="section-lg">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <h1 className="h2 mb-4">Privacy Policy</h1>
          <section className="mb-5">
            <p>Your privacy is important to us. Travly is owned and is the exclusive property of Flightspeed Tech Pty Ltd and its subsidiaries and affiliates ("Travly" or "we") are providing this notice to describe and explain our information collection practices and the choices you can make about the way your information is collected and used.</p>
          </section>
          <section className="mb-5">
            <h3 className="h4 mb-3">The Information We Collect and How We Collect It</h3>
            <p>In general, you can visit this website, and use associated Travly technologies, without telling us who you are or revealing any personal information about yourself. There are times, however, when we may gather information from you. Personal information like your name and e-mail address is obtained only when voluntarily submitted. Technical information, like details about your device and IP address, may be collected by virtue of your use of certain other Travly technologies. Travly collects this information in several ways:</p>
            <ul className="mb-4">
              <li>Through the Travly Profile page</li>
              <li>​Through registration, surveys and other online forms (some of which may be managed by third parties on behalf of Travly)</li>
              <li>​As part of an ongoing sales process</li>
              <li>​While providing technical support, consultation, live chat, or product information</li>
              <li>​Through the process of maintaining and upgrading our products</li>
              <li>​Through automated means such as communications protocols, e-mail communications and cookies</li>
              <li>​Through our mobile applications (some of which may be managed by third parties on behalf of Travly)</li>
              <li>​Through your use of social connectors and Travly-affiliated social networking areas</li>
            </ul>
            <p>To provide you with high-quality information, we may ask you to provide us with information regarding your interests and experiences with our products or services. Personal data or other data collected online may also be combined with information you provide to us through other sources such as product registration, call centers, or in conjunction with events such as trade shows, training seminars and conferences. Information that you supply will relate to the relationship that Travly has with you or your organization.</p>
          </section>
          <section className="mb-5">
            <h3 className="h4 mb-3">Web Server Logs</h3>
            <p>In the process of administering this site, we maintain and track usage through Web server logs. Web server logs provide information such as what types of browsers are accessing our site, what pages receive high traffic, and the times of day our servers experience significant load. We use this information to improve the content and navigation features of our site. Anonymized or aggregated forms of this data may be used to identify future features and functions to develop for the site and to provide better customer service.</p>
          </section>
          <section className="mb-5" id="cookies">
            <h3 className="h4 mb-3">Cookies</h3>
            <p>Certain technologies, called "cookies," can be used to provide tailored information from a website. A cookie is an element of data that a website can send to your browser, which your browser may then store on your system. Some pages on our site use cookies that assist you in navigating and using the site, for example, to help you access secure areas or use a shopping cart. Travly may set these types of cookies without requesting your consent.</p>
            <p>Other pages on the site may set cookies to collect aggregate usage information that we use to improve how the site works. Cookies may also be used on our site to remember certain choices you make, such as your geographical region, your user name, or other personalization or customization. We use these cookies to improve your user experience so we can better serve you when you return to our site. Travly will consider your use of the Web site as your consent for Travly to set these types of cookies.</p>
            <p>Travly may work with select third-party partners who set cookies to serve you Travly-related content, including Travly advertisements, on their sites or elsewhere on the Internet, based on your prior visits to our site. Travly also sets cookies that look at your preferences when you use the website so that Travly may offer you tailored marketing collateral, such as whitepapers, that you may be interested in. You may need to enable use of these kinds of cookies via the mechanism provided by Travly for these cookies to work. You can set your browser to notify you when you receive a cookie, giving you the chance to decide whether to accept it. By using our website, you agree that we can place these types of cookies on your system. For more information, please refer to user information provided with your Web browser. At this time, we do not respond to browser “do not track” signals, but we do provide you the option to opt out of behavioral advertising.  Please note that if you disable cookies, some site functionality may be limited.</p>
          </section>
          <section className="mb-5">
            <h3 className="h4 mb-3">How We Use Your Information</h3>
            <p>Travly may use your personal information to:</p>
            <ul>
              <li>​​Fulfill subscription requests, orders for software and services made online and to provide other information you request</li>
              <li>​​Make the website easier for you to use</li>
              <li>​​Help us customize content most relevant to your interests</li>
              <li>​​Notify you about important news concerning Travly</li>
              <li>​​Notify you of new products and services, product updates, technical support issues, events and special offers we think you may be interested in</li>
              <li>​​Promote our products and services on our website and third party partners’ websites</li>
              <li>​​Prevent and detect fraud or other prohibited or illegal activity</li>
              <li>​​Protect the security and integrity of the website</li>
            </ul>
          </section>
          <section className="mb-5">
            <h3 className="h4 mb-3">How We Share Your Information</h3>
            <p>We may share the personal data you provide with other Travly entities and/or business partners for purposes related to those described above. We will not sell, rent or lease to others your personally identifiable information. Travly reserves the right to disclose any and all pertinent information to law enforcement or other governmental officials as we, in our sole discretion, believe necessary or appropriate.</p>
          </section>
          <section className="mb-5">
            <h3 className="h4 mb-3">Our Commitment to Data Security</h3>
            <p>Travly is concerned about the security of your information. To prevent unauthorized access or disclosure, to maintain data accuracy, and to ensure the appropriate use of information, we have put in place reasonable physical, electronic, and managerial procedures to safeguard and secure the information we collect online. We use encryption technology when collecting or transferring sensitive data such as credit card information. Credit card numbers are used only for processing payment and are not used for other purposes.</p>
          </section>
          <section className="mb-5">
            <h3 className="h4 mb-3">External Links</h3>
            <p>Links to third-party websites from this website are provided solely as a convenience to you. If you use these links, you will leave this site. We have not reviewed these third-party sites and do not control them. Travly does not assume responsibility for any of these sites, their content, or their privacy policies. Travly does not endorse or make any representations about them or any information, software or other products or materials found there, or any results that may be obtained from using them. If you decide to access any of the third-party sites linked to this site, you do so at your own risk.</p>
          </section>
          <section className="mb-5">
            <h3 className="h4 mb-3">Changes to This Statement</h3>
            <p>If there are updates to the terms of Travly' online Privacy Statement, we will post those changes here and update the revision date in this document so that you will always know what information we collect online, how we use it, and what choices you have. Your continued use of Travly' website following the posting of changes to this Privacy Statement means you accept those changes.</p>
          </section>
          <section className="mb-5">
            <h3 className="h4 mb-3">Your Acceptance of These Terms</h3>
            <p>By using any Travly' website or any Travly mobile applications, you signify your agreement and consent to this Privacy Statement.</p>
          </section>
          <section className="mb-5">
            <h3 className="h4 mb-3">How to Contact Us</h3>
            <p>Should you have comments or questions about this Privacy Statement, you may e-mail us at: <a href="mailto:privacy@travly.me">privacy@travly.me</a></p>
          </section>
        </div>
      </div>
    </div>
  </article>

        <Index />
      </>
    );
  }
}

export default Privacy;
