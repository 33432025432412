import React from 'react';

const CookiePolicy = (props) => {
  const { setCookie } = props;
  return (
    <div className="alert alert-secondary alert-dismissible fade show" role="alert">
      <small>
        {' '}
We use cookies to offer you a better experience, analyze site traffic and serve targeted advertisements. By continuing to use this website, you consent to the use of cookies in accordance with our
        <a className="text-link" href="/privacy-policy"><strong>Cookie Policy</strong></a>
.
      </small>
      <button onClick={setCookie} type="button" className="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default CookiePolicy;
