/* eslint-disable */
const initialStateAlbums = {
  loading: false,
  error: {},
  statusError: false,
  albums: [],
  image: '',
  description: {},
  allImages: [],
  code: '',
  rawData: '',
};

const albums = (state = initialStateAlbums, action) => {
  switch (action.type) {
    case 'GET_ALBUM_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'GET_ALBUM_SUCCESS':
      return {
        ...state,
        loading: false,
        albums: action.payload.allData,
        image: action.payload.image,
        description: action.payload.description,
        allImages: action.payload.allImages,
        code: action.payload.code,
        rawData: action.payload.rawData,
      };
    case 'GET_ALBUM_FAILED':
      return {
        ...state,
        loading: false,
        albums: undefined,
        error: action.payload,
        statusError: true,
      };
    default: {
      return state;
    }
  }
};
export default albums;
