import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTag = (props) => {
  if (props.rawData) {
    const rawData = props.rawData.rawData;
    return (
      <Helmet>
        <meta
          data-property="og:title"
          property="og:title"
          content={`${rawData.title} - ${rawData.owner.first_name} ${rawData.owner.last_name} | Travly`}
        />
      </Helmet>
    );
  }
  else {
    return (
        <meta
          data-property="og:title"
          property="og:title"
          content="Travly"
        />
    ) 
  }
  
};

export default MetaTag;
