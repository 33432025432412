import React from 'react';

const AboutTitle = () => (
  <div className="mb-5 text-center">
    <h2 className="text-white">Easily create your Travly album</h2>
    <p className="lead text-white mt-3">Start creating your albums from your mobile device and share effortlessly.</p>
  </div>
);

export default AboutTitle;
