import React from 'react';
import Lightbox from 'react-images';
import Avatar from 'react-avatar';

class ListImages extends React.Component {
  constructor() {
    super();
    this.state = {
      lightboxIsOpen: false,
      arrayImage: [],
      currentImage: 0,
    };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }

  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      lightboxIsOpen: true,
      currentImage: index,
    });
  }

  closeLightbox() {
    this.setState({
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  gotoImage(index) {
    this.setState({
      currentImage: index,
    });
  }

  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) return;
    this.gotoNext();
  }

  checkImageProperty(image) {
    if (image.owner.hasOwnProperty('photo_url')) {
      return <Avatar className="profile-avatar" src={image.owner.photo_url} round size={40} />;
    }
    return <Avatar className="profile-avatar" name={`${image.owner.first_name} ${image.owner.last_name}`} round size={40} />;
  }

  renderGallery() {
    if(this.props.list){
        const { albumData } = this.props.list;
        const list = albumData;
        if (!list) return;
        const gallery = list.map((image, index) => {
            const i = image.counter;
            return (
                <div key={i} className="item">
                    <figure>
                        <div className="img-holder">
                            <a href={image.src} onClick={(e) => { this.openLightbox(i, e); }}><img className="img-fluid" src={image.src} alt="" /></a>
                            <div className="img-overlay">
                                <div className="img-profile align-items-center">
                                    {/* <Avatar className='profile-avatar' src={image.owner.photo_url} round={true} size={40} /> */}
                                    {this.checkImageProperty(image)}
                                    <span className="profile-name">
                    {' '}
                                        {`${image.owner.first_name} ${image.owner.last_name}`}
                                        {' '}
                  </span>
                                </div>
                            </div>
                        </div>
                    </figure>
                </div>
            );
        });
        return (
            <div>
                {gallery}
            </div>
        );
    }
  }

  render() {
    if (this.props.list)  {
        return (
            <div className="masonry">
                {this.renderGallery()}
                <Lightbox
                    currentImage={this.state.currentImage}
                    images={this.props.list.imageList}
                    isOpen={this.state.lightboxIsOpen}
                    onClose={this.closeLightbox}
                    onClickImage={this.handleClickImage}
                    onClickNext={this.gotoNext}
                    onClickPrev={this.gotoPrevious}
                    onClickThumbnail={this.gotoImage}
                />
            </div>
        );
    }
    else {
      return;
    }
  }
}


export default ListImages;
