import React from 'react';

const FooterSocial = props => (
  <div className="social-links text-center">
    <ul className="list-inline">
      <li className="list-inline-item px-1"><a href="https://www.facebook.com/travly.me" target="_blank" title="Facebook" rel="noopener noreferrer"><i className="fab fa-facebook" /></a></li>
      <li className="list-inline-item px-1"><a href="https://twitter.com/travly_me" target="_blank" title="Twitter" rel="noopener noreferrer"><i className="fab fa-twitter" /></a></li>
      <li className="list-inline-item px-1"><a href="https://www.instagram.com/travly.me/" target="_blank" title="Instagram" rel="noopener noreferrer"><i className="fab fa-instagram" /></a></li>
    </ul>
  </div>
);

export default FooterSocial;
