import React from 'react';
import logo from '../../assets/img/travly-logo.svg';
import { Link } from 'react-router-dom';
import ParenderError from '../../components/Prerender';

const Index = props => (
        <>
          <ParenderError />
          <header className="header">
            <nav className="navbar fixed-top navbar-dark">
              <div className="container">
                <a className="navbar-brand d-none d-flex m-auto" href="/"><img src={logo} alt="" /></a>
              </div>
            </nav>
          </header>

          <section className="bg-404 d-flex">
            <div className="container">
              <div className="row align-items-center justify-content-center h-100">
                <div className="col-lg-12 text-center">
                  <h1 className="text-light mb-4 px-lg-3">Oops! The page doesn't exist</h1>
                  <p className="lead text-light">Sorry, the page you're looking for isn’t here anymore</p>
                  <Link to="/" className="btn btn-primary btn-translate-hover my-3">
                    <i className="fas fa-chevron-left mr-3" />
                        Back home
                  </Link>
                </div>
              </div>
            </div>
            <div className="overlay" />
          </section>
        </>
);
export default Index;
