import React from 'react';
import FooterAbout from './FooterAbout';
import FooterSocial from './FooterSocial';
import FooterCompany from './FooterCompany';

class Index extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FooterAbout />
              <FooterSocial />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <FooterCompany />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Index;
