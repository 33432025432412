import React from 'react';
import { connect } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import Cookies from 'universal-cookie';
import moment from 'moment';
import animated from '../../helper/AnimateNav';
import Intercom from '../../components/Intercom';
import CookiePolicy from '../../components/CookiePolicy';
import HeaderNav from '../../components/HeaderNav';
import BGHero from '../../components/BGHero';
import Info from '../../components/Info';
import About from '../../components/About';
import Demo from '../../components/Demo';
import Download from '../../components/Download';
import Review from '../../components/Review';
import SignUp from '../../components/SignUp';
import Footer from '../../components/Footer';

const cookies = new Cookies();
const mapStateToProps = state => ({
  code: state.albums.code,
});

const mapDispatchToProps = dispacth => ({
});

class Landing extends React.Component {
  componentDidMount() {
    animated();
    window.prerenderReady = true;
    ReactPixel.pageView();
  }

  setCookie() {
    let expired = moment().add(6, 'months').format();
    expired = moment(expired).format('DD MMM YYYY');
    document.cookie = `cookie=${true}; expires=${moment().format('ddd')}, ${expired} 12:00:00 UTC; path=/`;
  }

  render() {
    let CookieCompo = '';
    const getCookie = cookies.get('cookie');
    if (!getCookie) {
      CookieCompo = <CookiePolicy setCookie={this.setCookie} />;
    }

    return (
            <>
              <Intercom appID="sfmq0vdv" />
              {CookieCompo}
              <HeaderNav />
              <BGHero />
              <Info />
              <About />
              <Demo />
              <Download />
              <Review />
              <SignUp />
              <Footer />
            </>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Landing);
