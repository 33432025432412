import React from 'react';
import logo from '../../assets/img/travly-logo.svg';

const HeaderNav = () => (
  <header className="header bg-transparent">
    <nav className="navbar d-flex justify-content-center align-items-center">
      <div className="container">
        <a href="/"><img className="logo" src={logo} alt="" /></a>
        <nav className="nav">
          <ul className="nav__links">
            <li><a href="#benefits">How it works</a></li>
            <li><a href="#features">Features</a></li>
            <li><a href="#reviews">Reviews</a></li>
            <li><a href="/blog/">Blog</a></li>
          </ul>
        </nav>
        <a href="mailto:support@travly.me" className="btn btn-outline-light btn-md nav__btn">Contact us</a>
        <div className="mobile__menu">
          <input id="toggle" type="checkbox" />
          <label className="hamburger" htmlFor="toggle">
            <div className="hamburger__top"></div>
            <div className="hamburger__middle"></div>
            <div className="hamburger__bottom"></div>
          </label>
          <div className="hamburger__nav">
            <div className="hamburger__nav--wrapper">
              <nav className="hamburger__nav--items">
                <a href="#benefits">How it works</a>
                <a href="#features">Features</a>
                <a href="#reviews">Reviews</a>
                <a href="/blog/">Blog</a>
              </nav>
            </div>
          </div>
        </div>
      </div>
      </nav>
  </header>
);

export default HeaderNav;
