import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';

import Nav from '../../components/Nav';
import ListAlbums from '../../components/ListAlbum';
import Footer from '../../components/Footer';
import AlbumsData from '../../store/action/albumsAction';
import LoadingView from '../../components/LoadingView';
import MapBox from '../../components/MapBox';
import Intercom from '../../components/Intercom';
import MetaTag from '../../components/MetaTag';
import OGMetaTagHeader from '../../components/OGMetaTagHeader';
import OGMetaTagTitle from '../../components/OGMetaTagTitle';
import OGMetaTagUrl from '../../components/OGMetaTagUrl';


const mapStateToProps = state => ({
  albumsData: state.albums.albums,
  statusError: state.albums.statusError,
  allImages: state.albums.allImages,
  rawData: state.albums,
});

const mapDispatchToProps = dispatch => ({
  retrievesAlbum(c) {
    dispatch(AlbumsData.retrieveAlbum(c));
  },
});

class Album extends React.Component {
  initializeReactGA() {
    window.ga('send', 'pageview', this.props.location.pathname);
  }

  componentDidMount() {
    this.initializeReactGA();
    const code = this.props.match.params.code;
    const username = this.props.match.params.username;
    this.props.retrievesAlbum({ code, username });
    ReactPixel.pageView();
  }

  componentDidUpdate() {
    if (this.props.rawData.loading === false) {
      window.prerenderReady = true;
    }
    window.prerenderReady = true;
  }

  setTitle(rawData) {
    const albumTitle = rawData.rawData.title;
    const obj = rawData.rawData.owner;
    if (obj) {
      const firstName = obj.first_name;
      const lastName = obj.last_name;
      document.title = `${albumTitle} - ${firstName} ${lastName} | Travly`;
    }
  }

  render() {
    const albumsData = this.props.albumsData;
    const allImages = this.props.allImages;
    const statusError = this.props.statusError;
    this.setTitle(this.props.rawData);
    if (statusError) {
      this.props.history.push('/404');
      return <h1>ERROR!</h1>;
    }
    if (albumsData.length && allImages.length) {
      return (
                <>
                  <OGMetaTagUrl />
                  <OGMetaTagHeader rawData={this.props.rawData} />
                  <OGMetaTagTitle rawData={this.props.rawData} />
                  <MetaTag rawData={this.props.rawData} />
                  <Intercom />
                  <Nav rawData={this.props.rawData} path={this.props.location} />
                  <section className="map-gui">
                    <MapBox albumsData={albumsData} />
                  </section>
                  <ListAlbums albumsData={albumsData} allImages={allImages} statusError={statusError} code={this.props.match.params.code} username={this.props.match.params.username} />
                  <Footer />
                </>
      );
    }

    return (
      <LoadingView />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Album);
