import React from 'react';

import appStoreBadge from '../../assets/img/app-store-badge.png';

const BGHero = () => (
<section className="bg-hero d-flex">
    <div className="container">
      <div className="row align-items-center justify-content-center h-100">
        <div className="col-lg-11 text-center">
          <h1 className="text-light mb-4">Share your epic travel journey on the go</h1>
          <a className="btn btn-primary btn-lg btn-translate-hover my-3" href="#signup">Join the mailing list</a>
          <div className="text-center mt-4">
            <a href="https://itunes.apple.com/us/app/travly-travel-photo-journal/id1112438513">
              <img className="img-fluid" src={appStoreBadge} width="168" height="50" alt="Download on the app store" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="overlay"></div>
  </section>
);

export default BGHero;
