import React from 'react'
import '../../assets/css/album-styles.css'
import logo from '../../assets/img/travly-logo.svg'
import { ToastContainer, toast } from 'react-toastify'
import animated from '../../helper/AnimateNav'
import 'react-toastify/dist/ReactToastify.css'
import avatar from '../../assets/img/avatar.svg'
import { connect} from 'react-redux'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Avatar from 'react-avatar'

const mapStateToProps = state => {
    return {
        albumsData: state.albums.albums,
        image: state.albums.image,
        description: state.albums.description
    }
}

class Index extends React.Component{
    constructor () {
        super()
        this.state = {
            value: '',
            copied: false,
        };
    }
    shareFb(){
        window.FB.ui({
            method: 'share',
            display: 'popup',
            href: window.location.href,
        }, function(response){
        });
    }
    notify () {
        toast("Copied to clipboard", { autoClose: 2000 });
    }

    displayFirstImage(imageUrl){
        return {
          backgroundImage: `url("${imageUrl}")`,
          content: '',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -10,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }
    }

    componentDidMount(){
        animated()
        this.displayFirstImage()
    }

    ownersName() {
        const { description: { owner={} }} = this.props
        const first = owner.first_name || ''
        const last = owner.last_name || ''
        return `${first} ${last}`
    }

    website(props) {
        const { description: { owner={} }} = props
        return owner.website || ''
    }

    render(){
        let photo_url = avatar
        if (this.props.rawData) {
            if (this.props.rawData.hasOwnProperty('rawData')) {
                photo_url = this.props.rawData.rawData.owner.photo_url
            }
        }

        var websiteDisplay = null;
        let website = this.website(this.props)
        if (website) {
            websiteDisplay = (<a href={website} className="badge badge-light">{website}</a>)
        }

        return(
            <>
                <div className="modal fade" id="shareModal" tabIndex="-1" role="dialog"
                     aria-labelledby="shareModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="shareModalLabel">Share this album</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ToastContainer
                                    position="top-right"
                                    autoClose={2000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                />
                                <ul className="list-unstyled pt-3">
                                    <li className="social-list-item">
                                        <a onClick={() =>{this.shareFb()}} className="btn btn-social-share btn-facebook" target="_blank" href=" " rel="noopener noreferrer">
                                            <i className="fab fa-facebook-square mr-3"></i>
                                            <span className="mr-3">Share on Facebook</span>
                                            <i className="fas fa-chevron-right ml-auto"></i>
                                        </a>
                                    </li>
                                    <li className="social-list-item">
                                        <a href={"http://twitter.com/home?status=" + window.location.href} className="btn btn-social-share btn-twitter" target="_blank" rel="noopener noreferrer" >
                                            <i className="fab fa-twitter-square mr-3"></i> <span className="mr-3">Share on Twitter</span>
                                            <i className="fas fa-chevron-right ml-auto"></i>
                                        </a>
                                    </li>
                                    <li className="social-list-item">
                                        <CopyToClipboard
                                            text= {window.location.href}
                                            onCopy={() => this.setState({copied: true})}
                                        >
                                            <button onClick={(e) =>this.notify(e)} className="btn btn-social-share btn-outline-dark" type="button"><i className="far fa-copy mr-3"></i> Public share link</button>
                                        </CopyToClipboard>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <header className="header-transparent" id="header-main">
                    <nav className="navbar bg-white" id="navbar-main">
                      <div className="container">
                        <a href="/">
                          <img className="logo" src={logo} alt="" />
                        </a>
                        <ul className="navbar-nav d-md-flex">
                          <button className="btn" type="button" data-toggle="modal" data-target="#shareModal">
                            <i className="fas fa-share"></i>
                          </button>
                        </ul>
                      </div>
                    </nav>
                </header>

                <section className="bg-hero d-flex">
                  <div style={this.displayFirstImage(this.props.image)}/>
                  <div className="container">
                    <div className="row align-items-center justify-content-center h-100">
                      <div className="col-lg-12">
                        <h1 className="display-1 text-light text-center mb-4 px-lg-3">{this.props.description.title}</h1>
                        <div className="col-lg-10 offset-lg-1">
                          <p className="lead text-light text-center px-lg-4">{this.props.description.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="public-profile text-center">
                      <Avatar className="mb-3" round={true} src={photo_url} size={64} />
                      <h6 className="name text-light">{this.ownersName()}</h6>
                      {websiteDisplay}
                    </div>
                  </div>
                  <div className="overlay"></div>
                </section>
            </>
        )
    }
}

export default connect(mapStateToProps,null)(Index)
