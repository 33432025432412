/* eslint-disable */
import React from 'react';
import '../../assets/css/spinner.css';

const LoadingView = () => (
  <div className="spinner">
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

export default LoadingView;
