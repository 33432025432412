import { Helmet } from 'react-helmet';
import React from 'react';

const ParenderError = () => (
  <Helmet>
    <meta
      data-name="prerender-status-code"
      name="prerender-status-code"
      content="404"
    />
  </Helmet>
);
export default ParenderError;
