import { Helmet } from 'react-helmet';
import React from 'react';
import PropTypes from 'prop-types';

const OGMetaTagTitle = props => {
  if (props.rawData) {
    return (
      <Helmet>
        <meta
          data-name='description'
          name="description"
          content={`
            ${props.rawData.rawData.title}, ${props.rawData.rawData.description},
          ${props.rawData.rawData.owner.username}, ${props.rawData.rawData.location}`}
          />
      </Helmet>
    )
  } else {
    return (
      <meta
        data-name='description'
        name="description"
        content="description"
        />
    )
  }
}

OGMetaTagTitle.propTypes = {
  rawData: PropTypes.object,
}

export default OGMetaTagTitle;
