/* eslint-disable */
import React from 'react';
import ReactIntercom from 'react-intercom';

const Intercom = props => (
  <div className="app">
    <ReactIntercom appID="sfmq0vdv" />
  </div>
);

export default Intercom;
