import React from 'react';
import sampleAlbum from '../../assets/img/sample-album.jpg';

const Demo = () => (
<section className="section-lg bg-light">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-10 offset-md-1 offset-lg-0 text-center text-lg-left">
          <h2 className="mb-4">Share your Travly albums</h2>
          <p>Publishing your albums on Travly gives you your own personal website to showcase your stunning photo galleries. Your can share your beautifully curated website anywhere simply by sharing the public link via email, Facebook or Twitter.</p>
          {/*<a className="btn btn-primary btn-lg btn-translate-hover mt-3 mb-5 mb-lg-0" href="album/">View template demo <i className="fas fa-chevron-right ml-3"></i></a>*/}
        </div>
        <div className="col-lg-6">
          <div className="device-chrome">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="iframe-container">
              <img className="img-fluid" src={sampleAlbum} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Demo;
