import React from 'react';

import AboutTitle from './AboutTitle';
import AboutImage from './AboutImage';
import AboutStep from './AboutStep';

const About = () => (
  <section className="section-lg bg-gradient" id="features">
    <div className="container">
      <AboutTitle />
      <div className="row align-items-center">
        <div className="col-lg-5">
          <AboutImage />
        </div>
        <div className="col-lg-6">
          <AboutStep />
        </div>
      </div>
    </div>
  </section>
);

export default About;
