import { Helmet } from 'react-helmet';
import React from 'react';

const OGMetaTagHeader = props => {
    if (props.rawData) {
      const { rawData } = props.rawData;
      return (
        <Helmet>
          <meta
            data-property="og:image"
            property="og:image"
            content={rawData.media[0].url}
          />
        </Helmet>
      );
    } else {
      return (
          <meta
            data-property="og:image"
            property="og:image"
            content="image:url"
          />
      ) 
    }
}

export default OGMetaTagHeader;
