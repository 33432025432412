import React, { Fragment } from 'react';
import ReactPixel from 'react-facebook-pixel';

import Home from './home';

const App = () => (
  <Fragment>
    <Home />
  </Fragment>
);

const advancedMatching = {};
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('471273647008304', advancedMatching, options);

export default App;
