import React from 'react';
import mailIcon from '../../assets/img/mail-icon.svg';

const SignUp = () => (
  <section className="section-lg bg-white" id="signup">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-8 offset-lg-2">
          <h2 className="mb-3 text-center">Join the mailing list</h2>
          <p className="text-center mb-4 px-lg-3">If you would like to get regular email updates about Travly, please fill out the form below.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div id="mc_embed_signup" className="signup mx-auto">
            <form action="https://travly.us16.list-manage.com/subscribe/post?u=8278fcc0fabe0f375e7054f47&amp;id=930ccd4f42" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate="">
              <div id="mc_embed_signup_scroll" className="input-group mb-3">
                <input type="email" name="EMAIL" className="email form-control" id="mce-EMAIL" placeholder="Enter your email" required="" />
                <img className="subscribe-icon" src={mailIcon} alt="subscribe" />
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                  <input type="text" name="b_8278fcc0fabe0f375e7054f47_930ccd4f42" tabindex="-1" value="" />
                </div>
                <div className="input-group-append">
                  <input className="btn btn-secondary btn-lg px-4" type="submit" value="Submit" name="subscribe" id="mc-embedded-subscribe" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default SignUp;
