import React from 'react';
import mapboxgl from 'mapbox-gl';
import { lineString } from '@turf/helpers';
import bezierSpline from '@turf/bezier-spline';
window.URL.createObjectURL = function(){};
mapboxgl.accessToken = 'pk.eyJ1Ijoicmlja2VyYmgiLCJhIjoiY2puYjZrcnA3MDB2aTN2cHJ1bGZmNHZrcSJ9.TZGrjPXgu-FJVYEVkg8zFw';
class MapBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 0,
      lat: 0,
    };
  }

  componentDidMount() {
    this.mapOnLoad(this.props.albumsData);
  }

  mapOnLoad(albumsData) {
    const center = [this.props.albumsData[Math.floor(this.props.albumsData.length / 2)][0].longitude, this.props.albumsData[Math.floor(this.props.albumsData.length / 2)][0].latitude];
    const latlngs = [];
    for (let i = 0; i < albumsData.length; i++) {
      latlngs.push([albumsData[i][0].longitude, albumsData[i][0].latitude]);
    }

    if (latlngs.length === 1) {
      latlngs.push(latlngs[0]);
    }

    const line = lineString(latlngs);
    const sharpness = 5;
    const curved = bezierSpline(line, [sharpness]);
    curved.properties = { sharpness: 5 };
    const feat = [];
    const temp = [];
    for (let i = 0; i < albumsData.length; i++) {
      const coor = {};
      coor.type = 'Feature';
      coor.geometry = {
        type: 'Point',
        coordinates: [albumsData[i][0].longitude, albumsData[i][0].latitude],
      };
      coor.properties = {
        title: albumsData[i][0].location,
      };
      temp.push([albumsData[i][0].longitude, albumsData[i][0].latitude]);
      feat.push(coor);
    }
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/light-v9',
      center,
      zoom: 2,
      maxZoom: 15,
      interactive: false,
    });

    map.on('load', () => {
      map.addSource('national-park', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: feat,
        },
      });
      map.addSource('route', {
        type: 'geojson',
        data: curved,
      });
      map.addLayer({
        id: 'route',
        source: 'route',
        type: 'line',
        paint: {
          'line-width': 4,
          'line-color': '#5756CF',
        },
      });

      map.addLayer({
        id: 'park-volcanoes',
        type: 'circle',
        source: 'national-park',
        paint: {
          'circle-radius': 4,
          'circle-color': 'white',
          'circle-stroke-color': '#5756CF',
          'circle-stroke-width': 4,
        },
        filter: ['==', '$type', 'Point'],
      });

      map.addLayer({
        id: 'points',
        type: 'symbol',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: feat,
          },
        },
        layout: {
          'icon-image': '{icon}-15',
          'text-field': '{title}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0.6],
          'text-anchor': 'top',
        },
      });

      if (window.IntersectionObserver) {
        const element = document.querySelector('.mapboxgl-map');
        const observer = new IntersectionObserver(((entries) => {
          if (entries[0].intersectionRatio) {
            const bounds = latlngs.reduce((bounds, coord) => bounds.extend(coord), new mapboxgl.LngLatBounds(latlngs[0], latlngs[0]));
            const options = { padding: 100 };
            map.fitBounds(bounds, options);
          }
        }), {
          root: null,
          threshold: [0.8],
        });
        observer.observe(element);
      }
    });
  }

  render() {
    return (
      <div className="" style={{ width: '100% !important', height: 600 }} ref={el => this.mapContainer = el} />
    );
  }
}

export default MapBox;
