import React from 'react';

const AboutStep = () => (
        <>
          <div className="d-flex align-items-start mb-5">
            <div className="pr-4">
              <div className="icon icon-shape shadow bg-white text-primary rounded-circle">
                <i className="fas fa-map-marker-alt" />
              </div>
            </div>
            <div className="icon-text">
              <h4 className="text-white">Share your location</h4>
              <p className="mb-0 text-white">Share your Travly journey and plot your location for family and friends to enjoy.</p>
            </div>
          </div>
          <div className="d-flex align-items-start mb-5">
            <div className="pr-4">
              <div className="icon icon-shape shadow bg-white text-primary rounded-circle">
                <i className="fas fa-toggle-on" />
              </div>
            </div>
            <div className="icon-text">
              <h4 className="text-white">Easily create your album</h4>
              <p className="mb-0 text-white">
                        Create your travel albums from the Travly app. Upload images and journal entries to create your travel journal as you go.
              </p>
            </div>
          </div>
          <div className="d-flex align-items-start mb-5">
            <div className="pr-4">
              <div className="icon icon-shape shadow bg-white text-primary rounded-circle">
                <i className="fas fa-wrench" />
              </div>
            </div>
            <div className="icon-text">
              <h4 className="text-white">Private or public? You decide</h4>
              <p className="mb-0 text-white">Keep your travel journal private or publish on travly.me and share with the world! You control the access to your albums.</p>
            </div>
          </div>
          <div className="d-flex align-items-start">
            <div className="pr-4">
              <div className="icon icon-shape shadow bg-white text-primary rounded-circle">
                <i className="fas fa-images" />
              </div>
            </div>
            <div className="icon-text">
              <h4 className="text-white">Promote your own website</h4>
              <p className="mb-0 text-white">Include your own profile when publishing your travel albums. Use Travly to promote your profile and drive traffic to your own portfolio or blog.</p>
            </div>
          </div>
        </>
);

export default AboutStep;
