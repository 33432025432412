/* eslint-disable */
import axios from 'axios';


class Albums {
  static sortByDate(response) {
    const dataMedia = response.data.media;
    dataMedia.sort((a, b) => {
      const dateA = new Date(a.date); const
        dateB = new Date(b.date);
      return dateA - dateB; // sort by date ascending
    });
    return dataMedia;
  }

  static arrLocation(dataMedia) {
    const location = [];
    for (let i = 0; i < dataMedia.length; i++) {
      if (location.indexOf(dataMedia[i].location) === -1) {
        location.push(dataMedia[i].location);
      }
    }
    return location;
  }

  static groupByLocation(dataMedia, location) {
    const grouping = [];
    let temp = [];
    let counter = 0;
    for (let i = 0; i < location.length; i++) {
      temp = [];
      for (let j = 0; j < dataMedia.length; j++) {
        if (location[i] === dataMedia[j].location) {
          dataMedia[j].counter = counter++;
          temp.push(dataMedia[j]);
        }
      }
      grouping.push(temp);
    }
    return grouping;
  }

  static getAllImage(grouping) {
    const arrTemp = [];
    let obj = {};
    for (let i = 0; i < grouping.length; i++) {
      for (let j = 0; j < grouping[i].length; j++) {
        obj = {};
        obj.url = grouping[i][j].url;
        obj.location = grouping[i][j].location;
        obj.owner = grouping[i][j].owner;
        arrTemp.push(obj);
      }
    }
    return arrTemp;
  }

  static retrieveAlbum(payload) {
    return (dispatch) => {
      dispatch({ type: 'GET_ALBUM_REQUEST' });
      const { username, code } = payload;
      // axios.get(`https://dev-api.travly.me/v1/user/${username}/album/${code}`)
      axios.get(`https://api.travly.me/v1/user/${username}/album/${code}`)
        .then((response) => {
          const dataMedia = this.sortByDate(response);
          const location = this.arrLocation(dataMedia);
          const grouping = this.groupByLocation(dataMedia, location);
          const arrTemp = this.getAllImage(grouping);
          dispatch({
            type: 'GET_ALBUM_SUCCESS',
            payload: {
              rawData: response.data,
              allData: grouping,
              allImages: arrTemp,
              image: grouping[0][0].url,
              description: response.data,
              code,
              username,
            },
          });
        })
        .catch((err) => {
          dispatch({ type: 'GET_ALBUM_FAILED', payload: err.response });
        });
    };
  }
}
export default Albums;
