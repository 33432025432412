import React from 'react';
import profile1 from '../../assets/img/img-profile-1.jpg';
import profile2 from '../../assets/img/img-profile-2.jpg';
import profile3 from '../../assets/img/img-profile-3.jpg';
import spainFlag from '../../assets/img/flags/spain.png';
import ukFlag from '../../assets/img/flags/united-kingdom.png';
import usFlag from '../../assets/img/flags/united-states.png';
import fourStars from '../../assets/img/rating-4-stars.svg';

const Review = () => (
  <section className="section-lg bg-secondary" id="reviews">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <h2 className="h2 text-center mb-4">What people are saying</h2>
          <p className="lead text-center">We have a global community of globetrotting customers who enjoy using the Travly app. You're in great company.</p>
        </div>
      </div>
      <div className="row align-items-center py-5">
        <div className="col-lg-4 col-md-8 offset-md-2 offset-lg-0">
          <div className="bg-white rounded mb-4 p-4 p-lg-5 text-center">
            <img className="img-fluid u-sm-avatar rounded-circle mx-auto mb-3" src={profile1} alt="" />
            <p className="small">"Travly is a great way to share my photos with family and firends. I love how easy it is to create an album and highlight my favourite trips."</p>
            <h6 className="d-block mt-4"><img className="mr-2" src={usFlag} width="24" height="24" alt="Spain" />Rachel Singer</h6>
            <img className="mt-4" src={fourStars} alt="4 stars" />
          </div>
        </div>
        <div className="col-lg-4 col-md-8 offset-md-2 offset-lg-0">
          <div className="bg-white rounded mb-4 p-4 p-lg-5 text-center">
            <img className="img-fluid u-sm-avatar rounded-circle mx-auto mb-3" src={profile2} alt="" />
            <p className="small">"Travly has become my go-to tool for photo sharing. I can quickly publish my albums and share it with ease. The gallery page is really powerful"</p>
            <h6 className="d-block mt-4"><img className="mr-2" src={ukFlag} width="24" height="24" alt="Spain" />Keith Hancock</h6>
            <img className="mt-4" src={fourStars} alt="4 stars" />
          </div>
        </div>
        <div className="col-lg-4 col-md-8 offset-md-2 offset-lg-0">
          <div className="bg-white rounded mb-4 p-4 p-lg-5 text-center">
            <img className="img-fluid u-sm-avatar rounded-circle mx-auto mb-3" src={profile3} alt="" />
            <p className="small">"I really love using the Travly app, the latest upgrade is beautiful! It's so intuitive and really practical for me when I'm traveling."</p>
            <h6 className="d-block mt-4"><img className="mr-2" src={spainFlag} width="24" height="24" alt="Spain" />Ellie Hernandez</h6>
            <img className="mt-4" src={fourStars} alt="4 stars" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Review;
