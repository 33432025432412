/* eslint-disable */
import $ from 'jquery';
const animated = () => {
  // esl
  $(document).ready(() => {
    const $navbar = $('#navbar-main');

    stickyHeader();
    $(window).scroll(() => {
      stickyHeader();
    });

    function stickyHeader() {
      if ($(window).scrollTop() > 0) {
        if (!$navbar.hasClass('sticky')) {
          $navbar.addClass('sticky');
        }
      } else {
        $navbar.removeClass('sticky');
      }
    }

    $('.navbar-toggler').click(() => {
      $('.navbar').toggleClass('navbar-collapsed');
      $('.navbar-collapse').toggleClass('show');
      return false;
    });

    $('a[href*="#"]').on('click', function (event) {
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== '') {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        const hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top,
        }, 1000, () => {
          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        });
      } // End if
    });
  });
};

export default animated;
