import React from 'react';
import '../../assets/css/styles.css';
import { Redirect } from 'react-router-dom';
import format from '../../helper/formatDate';
import ListImages from './ListImages';


class ListAlbum extends React.Component {
  constructor() {
    super();
    this.state = {
      albums: [],
    };
    this.throwError = this.throwError.bind(this);
  }

  throwError() {
    return <Redirect to="/404" />;
  }

  render() {
    if (this.props.albumsData)  {
        return (
            <>
                {
                    this.props.statusError
                        ? this.throwError()
                        : this.props.albumsData.map((img, index) => {
                            const list = {
                                albumData: img.map(({
                                                        url, location, counter, owner,
                                                    }) => ({
                                    src: url,
                                    counter,
                                    caption: location,
                                    owner,
                                })),
                                imageList: this.props.allImages.map(({ url, location }) => ({
                                    src: url,
                                    caption: location,
                                })),
                            };
                            return (
                                <section className="section" key={index}>
                                    <div className="container">
                                        <div className="row mb-4">
                                            <div className="col-md-6">
                                                <div className="location d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                    <div className="map-pin" />
                                                    <h3>{img[0].location}</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                {
                                                    format(img[img.length - 1].date) === format(img[0].date)
                                                        ? <div className="text-center text-md-right">{format(img[0].date)}</div>
                                                        : (
                                                            <div className="text-center text-md-right">
                                                                {format(img[0].date)}
                                                                {' '}
                                                                -
                                                                {' '}
                                                                {format(img[img.length - 1].date)}
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <ListImages list={list} />
                                        </div>
                                    </div>
                                </section>
                            );
                        })
                }
            </>
        );
    }
  }
}

export default ListAlbum;
